<template>
  <div class="page">
    <el-container>
      <el-header>
        <Head :slide="slide" @logout="logout" :is_pelvic="1"></Head>
      </el-header>
      <el-main>
        <div class="">
          <ul class="bread-crumb">
            <li
              class="item item-3 fill"
              @click="$router.push('/pelvic/measure?id=' + id)"
            >
              {{ $t("report.post") + $t("report.measure") }}
            </li>
            <li class="item item-3 fill">
              {{ $t("report.post") + $t("report.install") }}
            </li>
            <li class="item item-4">
              {{ $t("report.post") + $t("report.stand") }}
            </li>
            <li class="item item-5">
              {{ $t("report.post") + $t("report.create") }}
            </li>
          </ul>

          <div class="main">
            <div class="right-side">
              <el-form
                :model="ruleForm"
                ref="ruleForm"
                label-width="100px"
                class="form"
                label-position="left"
              >
                <div class="reference">
                  <h2 class="content-title"></h2>
                  <el-form-item :label="$t('report.reference') + ':'">
                    <el-select
                      v-model="reference"
                      :placeholder="$t('report.placeholder')"
                    >
                      <el-option
                        v-for="item in refOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <div class="left-side-form">
                    <div class="left-side-form-item">
                      <h2 class="content-title">
                        <div class="title">
                          <span>{{ $t("report.nearRing") }}:</span>
                        </div>
                      </h2>

                      <el-form-item :label="$t('report.ringType') + ':'">
                        <el-select
                          v-model="nearType"
                          :placeholder="$t('report.placeholder')"
                        >
                          <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('report.size') + ':'">
                        <el-select
                          v-model="nearSize"
                          :placeholder="$t('report.placeholder')"
                        >
                          <el-option
                            v-for="item in nearSizeOptions"
                            :key="item.value"
                            :label="item.label + 'mm'"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('report.direction') + ':'">
                        <el-select
                          v-model="nearDirection"
                          :placeholder="$t('report.placeholder')"
                        >
                          <el-option
                            v-for="item in dirOptionsNear"
                            :key="item.value"
                            :label="item.label"
                            :disabled="item.disabled"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>

                    <div class="left-side-form-item">
                      <h2 class="content-title">
                        <div class="title">
                          <span>{{ $t("report.farRing") }}:</span>
                        </div>
                      </h2>
                      <el-form-item :label="$t('report.ringType') + ':'">
                        <el-select
                          v-model="farType"
                          :placeholder="$t('report.placeholder')"
                        >
                          <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('report.size') + ':'">
                        <el-select
                          v-model="farSize"
                          :placeholder="$t('report.placeholder')"
                        >
                          <el-option
                            v-for="item in farSizeOptions"
                            :key="item.value"
                            :label="item.label + 'mm'"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('report.direction') + ':'">
                        <el-select
                          v-model="farDirection"
                          :placeholder="$t('report.placeholder')"
                        >
                          <el-option
                            v-for="item in dirOptionsFar"
                            :key="item.value"
                            :label="item.label"
                            :disabled="item.disabled"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                </div>

                <div class="green">
                  <h2 class="content-title">
                    <div class="title green">
                      <span>{{ $t("report.ring") }}-{{ $t("report.front") }}</span>
                    </div>
                  </h2>

                  <div class="right-side-form">
                    <div class="right-side-form-item">
                      <el-form-item
                        class="green"
                        label-width="150px"
                        :label="$t('report.angle') + '(°):'"
                      >
                        <el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.front.angle"
                        ></el-input>
                      </el-form-item>
                      <el-form-item class="green" label-width="auto">
                        <el-radio-group
                          :disabled="lock"
                          v-model="ruleForm.front.angleDir"
                        >
                          <el-radio class="radio" label="0">{{
                            $t("report.param20")
                          }}</el-radio>
                          <el-radio class="radio" label="1">{{
                            $t("report.param21")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>

                    <div class="right-side-form-item">
                      <el-form-item
                        class="green"
                        label-width="150px"
                        :label="$t('report.param26') + '(mm):'"
                      >
                        <el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.front.distance"
                        ></el-input>
                      </el-form-item>
                      <el-form-item class="green" label-width="auto">
                        <el-radio-group
                          :disabled="lock"
                          v-model="ruleForm.front.distanceDir"
                        >
                          <el-radio class="radio" label="0">{{
                            $t("report.param14")
                          }}</el-radio>
                          <el-radio class="radio" label="1">{{
                            $t("report.param15")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                </div>

                <div class="yellow">
                  <h2 class="content-title">
                    <div class="title yellow">
                      <span>{{ $t("report.ring") }}-{{ $t("report.side") }}</span>
                    </div>
                  </h2>

                  <div class="right-side-form">
                    <div class="right-side-form-item">
                      <el-form-item
                        class="yellow"
                        label-width="150px"
                        :label="$t('report.angle') + '(°):'"
                      >
                        <el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.side.angle"
                        ></el-input>
                      </el-form-item>
                      <el-form-item class="yellow" label-width="auto" label="">
                        <el-radio-group :disabled="lock" v-model="ruleForm.side.angleDir">
                          <el-radio class="radio" label="0">{{
                            $t("report.param22")
                          }}</el-radio>
                          <el-radio class="radio" label="1">{{
                            $t("report.param23")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>

                    <div class="right-side-form-item">
                      <el-form-item
                        class="yellow"
                        label-width="150px"
                        :label="$t('report.param26') + '(mm):'"
                      >
                        <el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.side.distance"
                        ></el-input>
                      </el-form-item>
                      <el-form-item class="yellow" label-width="auto" label="">
                        <el-radio-group
                          :disabled="lock"
                          v-model="ruleForm.side.distanceDir"
                        >
                          <el-radio class="radio" label="0">{{
                            $t("report.param17")
                          }}</el-radio>
                          <el-radio class="radio" label="1">{{
                            $t("report.param18")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                </div>

                <div class="purple">
                  <h2 class="content-title">
                    <div class="title purple">
                      <span>{{ $t("report.ring") }}-{{ $t("report.axis") }}</span>
                    </div>
                  </h2>

                  <div class="right-side-form">
                    <div class="right-side-form-item">
                      <el-form-item
                        class="purple"
                        label-width="150px"
                        :label="$t('report.angle') + '(°):'"
                      >
                        <el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.axis.angle"
                        ></el-input>
                      </el-form-item>
                      <el-form-item class="purple" label-width="auto" label="">
                        <el-radio-group :disabled="lock" v-model="ruleForm.axis.angleDir">
                          <el-radio class="radio" label="0">{{
                            $t("report.param25")
                          }}</el-radio>
                          <el-radio class="radio" label="1">{{
                            $t("report.param24")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>

                    <div class="right-side-form-item">
                      <el-form-item
                        class="purple"
                        label-width="150px"
                        :label="$t('report.param19') + '(mm):'"
                      >
                        <el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.axis.distance"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="btn-submit" @click="dialogVisible = true">
                  {{ $t("report.nextStep") }}
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      :title="$t('report.paramsConfirm2')"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <div class="dialog">
        <ul>
          <li>{{ $t("report.theFront") }}</li>
          <li>
            {{
              ruleForm.front.angleDir == "0" ? $t("report.param20") : $t("report.param21")
            }}
            {{ ruleForm.front.angle + "°" }}
          </li>
          <li>
            {{
              ruleForm.front.distanceDir == "0"
                ? $t("report.param14")
                : $t("report.param15")
            }}
            {{ ruleForm.front.distance + "mm" }}
          </li>
        </ul>
        <ul>
          <li>{{ $t("report.theSide") }}</li>
          <li>
            {{
              ruleForm.side.angleDir == "0" ? $t("report.param22") : $t("report.param23")
            }}
            {{ ruleForm.side.angle + "°" }}
          </li>
          <li>
            {{
              ruleForm.side.distanceDir == "0"
                ? $t("report.param17")
                : $t("report.param18")
            }}
            {{ ruleForm.side.distance + "mm" }}
          </li>
        </ul>
        <ul>
          <li>{{ $t("report.theAxis") }}</li>
          <li>
            {{
              ruleForm.axis.angleDir == "0" ? $t("report.param25") : $t("report.param24")
            }}
            {{ ruleForm.axis.angle + "°" }}
          </li>
          <li>{{ $t("report.param19") }} {{ ruleForm.axis.distance + "mm" }}</li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("report.BackToRevise")
        }}</el-button>
        <el-button type="primary" @click="submit">{{
          $t("report.ConfirmData")
        }}</el-button>
      </span>
    </el-dialog>
    <div class="popover" :style="popoverPosition" v-show="popoverShow">
      <div v-html="popoverContent"></div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
// import Konva from 'konva';
import Zoom from "../../components/Zoom";
export default {
  components: {
    Zoom,
  },
  data() {
    return {
      popoverShow: false,
      popoverPosition: { top: 0, left: 0 },
      popoverContent: "",
      dialogVisible: false,
      id: "", //病例id
      ruleForm: {
        front: {
          angle: 0, //角度
          distance: 0, //中心位移
          angleDir: "0", //角度方向
          distanceDir: "0", //位移方向
        },
        side: {
          angle: 0,
          distance: 0,
          angleDir: "0",
          distanceDir: "0",
        },
        axis: {
          angle: 0,
          distance: 0, //起点偏移
          angleDir: "0",
        },
      },
      refOptions: [
        //参考节段选项
        {
          value: 0,
          label: this.$t("pelvic.ref1"),
        },
        {
          value: 1,
          label: this.$t("pelvic.ref2"),
        },
      ],

      typeOptions: [
        //环类型选项
        {
          value: 0,
          label: this.$t("report.ring1"),
        },
        {
          value: 1,
          label: this.$t("report.ring2"),
        },
        {
          value: 2,
          label: this.$t("report.ring3"),
        },
      ],
      nearSizeOptions: [], //环规格选项
      farSizeOptions: [],
      reference: 0, //参考节段
      direction: 0, //朝向
      nearDirection: 0,
      farDirection: 0,
      nearType: "", //近侧环类型
      farType: "", //远侧环类型
      nearSize: "", //近侧环规格
      farSize: "", //远侧环规格
      stage: null, //画布
      layer: null, //画笔
      stage2: null, //画布2
      layer2: null, //画笔2
      frontImageObj: new Image(), //正位初始图片对象
      sideImageObj: new Image(), //侧位初始图片对象
      oldWidth: document.documentElement.clientWidth * 0.32, // 画布初始宽度
      oldHeight: document.documentElement.clientHeight * 0.8, // 画布初始高度
      widthIn: document.documentElement.clientWidth * 0.32, // 当前画布宽度
      heightIn: document.documentElement.clientHeight * 0.8, // 当前画布高度
      widthIn2: document.documentElement.clientWidth * 0.32,
      heightIn2: document.documentElement.clientHeight * 0.8,
      xRay: null, //正位图
      xRayS: null, //侧位图
      base: [],
      isConfirm: false,
      disabled: false,
      actual: 200, //参考线距离mm
      dist: 0, //参考线距离px
      frontTopTool: null, //正位上工具
      frontBottomTool: null, //正位下工具
      sideTopTool: null, //正位上工具
      sideBottomTool: null, //正位下工具
      multiple: 1,
      scale: 1,
      slideText: "",
      slide: "",
      frontSlide: "0", // 正位患侧
      sideSlide: "0", // 侧位患侧
      ringLoaded: false,
      lock: false, //是否锁定
      centerTool: false, //圆心辅助定工具
    };
  },
  watch: {
    async nearType(n, o) {
      this.nearSizeOptions = await this.getSolidRing(n);
      this.nearDirection = this.ringLoaded ? 0 : this.nearDirection;
      if (this.nearSizeOptions.length) {
        if (this.nearCallback) {
          return this.nearCallback();
        } else {
          this.nearSize = 0;
        }
      } else {
        this.nearSize = "";
      }
    },
    async farType(n, o) {
      this.farSizeOptions = await this.getSolidRing(n);
      this.farDirection = this.ringLoaded ? 0 : this.farDirection;
      if (this.farSizeOptions.length) {
        if (this.farCallback) {
          return this.farCallback();
        } else {
          this.farSize = 0;
        }
      } else {
        this.farSize = "";
      }
    },
    reference(newVal, oldVal) {
      // this.dotDragBound();
      // if(newVal == 0){
      //     this.trFBR.hide();
      //     this.trSBR.hide();
      //     this.trFTR.show();
      //     this.trSTR.show();
      // }else{
      //     this.trFBR.show();
      //     this.trSBR.show();
      //     this.trFTR.hide();
      //     this.trSTR.hide();
      // }
    },
  },

  computed: {
    //朝向:0=无,1=前侧,2=后侧,3=内侧,4=外侧 全环无朝向
    dirOptionsNear() {
      let dir = [
        {
          value: 0,
          label: this.$t("report.not"),
        },
      ];
      if (this.nearType != 0) {
        dir.push(
          {
            value: 1,
            label: this.$t("report.dir1"),
          },
          {
            value: 2,
            label: this.$t("report.dir2"),
          }
        );
      }
      if (this.nearType == 2) {
        dir.push(
          {
            value: 3,
            label: this.$t("report.dir3"),
          },
          {
            value: 4,
            label: this.$t("report.dir4"),
          }
        );
      }
      return dir;
    },
    dirOptionsFar() {
      let dir = [
        {
          value: 0,
          label: this.$t("report.not"),
        },
      ];
      if (this.farType != 0) {
        dir.push(
          {
            value: 1,
            label: this.$t("report.dir1"),
          },
          {
            value: 2,
            label: this.$t("report.dir2"),
          }
        );
      }
      if (this.farType == 2) {
        dir.push(
          {
            value: 3,
            label: this.$t("report.dir3"),
          },
          {
            value: 4,
            label: this.$t("report.dir4"),
          }
        );
      }
      return dir;
    },
  },

  async created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      let res = await this.$api.getReport({
        before_after: 1,
        id: this.id,
      });
      if (res.code) {
        this.base = res.data.base;

        this.frontSlide = this.base[0].left_right
          ? JSON.parse(this.base[0].left_right).slide
          : this.slide;
        this.sideSlide = this.base[1].left_right
          ? JSON.parse(this.base[1].left_right).slide
          : this.slide;
        this.slideText = res.data.case.slide_text;
        this.slide = res.data.case.slide;
        const install = res.data.install;

        this.nearSizeOptions = await this.getSolidRing(install.solid_type);
        this.farSizeOptions = await this.getSolidRing(install.solid_far_type);

        this.reference = Number(install.far_near);
        this.nearType = Number(install.solid_type);
        this.farType = Number(install.solid_far_type);
        let nearIndex = this.nearSizeOptions.findIndex((val) => {
          return val.id == install.solid_ring_id;
        });
        let farIndex = this.farSizeOptions.findIndex((val) => {
          return val.id == install.solid_far_ring_id;
        });

        this.ruleForm.front.angle = install.just_angle;
        this.ruleForm.front.distance = install.just_dis;
        this.ruleForm.front.angleDir = install.just_down;
        this.ruleForm.front.distanceDir = install.just_in_out_slide;

        this.ruleForm.side.angle = install.ce_angle;
        this.ruleForm.side.distance = install.ce_dis;
        this.ruleForm.side.angleDir = install.ce_down;
        this.ruleForm.side.distanceDir = install.ce_before_after_slide;

        this.ruleForm.axis.angle = install.zhou_angle;
        this.ruleForm.axis.distance = install.zhou_dis;
        this.ruleForm.axis.angleDir = install.zhou_down;

        this.ringLoaded = true;
      }
    } else {
      this.$message.error("病例不存在");
      setTimeout((res) => {
        this.$router.go(-1);
      }, 500);
    }
    //
  },
  mounted() {
    // document.getElementsByClassName("right-side")[0].style.height =
    //   this.configKonva.height + "px";
    // document.getElementsByClassName("left-side")[0].style.height =
    //   this.configKonva.height + "px";

    let that = this;
    this.frontImageObj.onload = function (e) {
      // that.resizeImg(this);
      // that.layer.draw();
      that.xRay.rotation(that.base[0].angle);
      that.heightIn = that.xRay.height();
      that.widthIn = that.xRay.width();
    };
    this.sideImageObj.onload = function (e) {
      // that.resizeImg(this);
      // that.layer2.draw();
      that.unifyImg(this);
      that.xRayS.rotation(that.base[1].angle);
      that.heightIn2 = that.xRayS.height();
      that.widthIn2 = that.xRayS.width();
      that.xRayS.y(that.xRayS.y() + Number(that.base[1].move));
      that.layer2.draw();
    };
  },
  methods: {
    //同比比例
    unifyImg(image) {
      let proportion1 = this.base[0].proportion;
      let proportion2 = this.base[1].proportion;
      let unify = proportion1 / proportion2;
      image.height = image.height * unify;
      image.width = image.width * unify;
      this.isUnify = true;
      return image;
    },
    //正位患侧信息修改
    frontSlideChange() {
      this.frontSlide = this.frontSlide == "0" ? "1" : "0";
      this.saveSlide();
    },
    //侧位患侧信息修改
    sideSlideChange() {
      this.sideSlide = this.sideSlide == "0" ? "1" : "0";
      this.saveSlide();
    },
    //保存患侧信息
    saveSlide() {
      this.$api.saveLeftRight({
        id: this.id,
        before_after: 1,
        left_right: JSON.stringify([
          { slide: this.frontSlide },
          { slide: this.sideSlide },
        ]),
      });
    },
    //获取固环
    async getSolidRing(type) {
      let res = await this.$api.getSolidRing({ limit: 999, type });
      if (res.code) {
        let size = [];
        if (!res.data.length) {
          return [];
        }
        res.data.map((val, index) => {
          size.push({
            id: val.id,
            value: index,
            label: val.long,
          });
        });
        return size;
      }
      this.$message.error("固环获取失败");
      return [];
    },

    //提交，下一步
    submit() {
      if (this.disabled) return false;
      this.dialogVisible = false;
      this.disabled = true;

      let params = {
        id: this.id,
        far_near: this.reference,
        toward: this.nearDirection,
        just_in_out_slide: this.ruleForm.front.distanceDir,
        just_dis: this.ruleForm.front.distance,
        just_angle: this.ruleForm.front.angle,
        just_down: this.ruleForm.front.angleDir,
        ce_before_after_slide: this.ruleForm.side.distanceDir,
        ce_dis: this.ruleForm.side.distance,
        ce_angle: this.ruleForm.side.angle,
        ce_down: this.ruleForm.side.angleDir,
        zhou_dis: this.ruleForm.axis.distance,
        zhou_angle: this.ruleForm.axis.angle,
        zhou_down: this.ruleForm.axis.angleDir,
        solid_far_ring_id: this.farSizeOptions.length
          ? this.farSizeOptions[this.farSize].id
          : "",
        toward_far: this.farDirection,
        solid_far_type: this.farType,
        solid_type: this.nearType,
        solid_ring_id: this.nearSizeOptions.length
          ? this.nearSizeOptions[this.nearSize].id
          : "",
      };
      this.$api
        .saveInstallAfter(params)
        .then((res) => {
          //术前报告
          // this.disabled = false;
          if (res.code) {
            return this.$router.push("/pelvic/stand?id=" + this.id);
          }
          this.disabled = false;
          this.$message.error(res.msg);
        })
        .catch((err) => {
          this.disabled = false;
        });
    },
    reSection(type = "front") {
      let node =
        type == "front"
          ? this.$refs.frontBottomLineCenter.getNode()
          : this.$refs.sideBottomLineCenter.getNode();
      let points = node.points();
      let section =
        type == "front"
          ? this.$refs.frontSection.getNode()
          : this.$refs.sideSection.getNode();
      section.x(this.getPointX(points[2], points[3], points[4], points[5], section.y()));
    },

    //返回上一步
    lastStep() {
      this.$router.push("/pelvic/measure?id=" + this.id);
    },
    //退出登录
    logout() {
      this.$confirm("是否退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api
            .logout()
            .then((res) => {
              if (res.code) {
                return this.$router.push("/login");
              }
              this.$message.error(res.msg);
            })
            .catch((err) => {
              // this.$message.error('系统繁忙');
            });
        })
        .catch(() => {});
    },

    async complete() {
      if (this.disabled) return false;
      this.dialogVisible = false;
      this.disabled = true;
      const fDot = this.reference
        ? this.$refs.frontBottomDot.getNode()
        : this.$refs.frontTopDot.getNode();
      const sDot = this.reference
        ? this.$refs.sideBottomDot.getNode()
        : this.$refs.sideTopDot.getNode();
      const fTool = this.reference ? this.frontBottomTool : this.frontTopTool;
      const sTool = this.reference ? this.sideBottomTool : this.sideTopTool;
      const fRing = this.reference
        ? this.$refs.frontBottomRing.getNode()
        : this.$refs.frontTopRing.getNode();
      const sRing = this.reference
        ? this.$refs.sideBottomRing.getNode()
        : this.$refs.sideTopRing.getNode();
      let params = {
        id: this.id,
        far_near: this.reference,
        solid_type: this.nearType,
        solid_ring_id: this.nearSizeOptions.length
          ? this.nearSizeOptions[this.nearSize].id
          : "",
        toward: this.nearDirection,
        just_in_out_slide: this.ruleForm.front.distanceDir,
        just_dis: this.ruleForm.front.distance,
        just_angle: this.ruleForm.front.angle,
        just_down: this.ruleForm.front.angleDir,
        ce_before_after_slide: this.ruleForm.side.distanceDir,
        ce_dis: this.ruleForm.side.distance,
        ce_angle: this.ruleForm.side.angle,
        ce_down: this.ruleForm.side.angleDir,
        zhou_dis: this.ruleForm.axis.distance,
        zhou_angle: this.ruleForm.axis.angle,
        zhou_down: this.ruleForm.axis.angleDir,
        solid_far_ring_id: this.farSizeOptions.length
          ? this.farSizeOptions[this.farSize].id
          : "",
        toward_far: this.farDirection,
        solid_far_type: this.farType,
        coordinates: {
          scale: this.scale,
          fDotX: fDot.x() + fTool.x(),
          fDotY: fDot.y() + fTool.y(),
          sDotX: sDot.x() + sTool.x(),
          sDotY: sDot.y() + sTool.y(),
          fRing: [
            fRing.points()[0] + fTool.x(),
            fRing.points()[1] + fTool.y(),
            fRing.points()[2] + fTool.x(),
            fRing.points()[3] + fTool.y(),
          ],
          sRing: [
            sRing.points()[0] + sTool.x(),
            sRing.points()[1] + sTool.y(),
            sRing.points()[2] + sTool.x(),
            sRing.points()[3] + sTool.y(),
          ],
        },
      };
      this.$api
        .saveInstallAfter(params)
        .then((res) => {
          //术前报告
          // this.disabled = false;
          if (res.code) {
            return this.$router.push("/post/stand?id=" + this.id);
          }
          this.disabled = false;
          this.$message.error(res.msg);
        })
        .catch((err) => {
          this.disabled = false;
        });
    },
  },
};
</script>
<style scoped>
.warp {
  width: 1440px;
}

.el-main {
  padding: 0 20px;
  background: #f1f5f7;
}

.head {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-header {
  background: #ffffff;
  text-align: right;
}

.btn-logout {
  display: inline-block;
  padding: 8px 17px;
  padding-left: 37px;
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 14px;
  color: #364284;
  margin-right: 50px;
  position: relative;
}

.btn-logout::before {
  content: "";
  background: url(../../assets/img/off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0%, -50%);
}

.btn-logout:hover {
  cursor: pointer;
}

.text-upload {
  margin: 17px 0;
  text-align: center;
  color: #7ecdf6;
  text-decoration: underline;
  cursor: pointer;
}

.main {
  /* height: ; */
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
}

.main .canvas {
  width: 76.88%;
  /* height: 66.94%; */
  height: 540px;
  border-radius: 4px;
  background: #000000;
}

.main .right-side,
.main .left-side {
  /* width: 16%; */
  /* height: 540px; */
  background: #ffffff;
  border-radius: 4px;
  overflow-y: auto;
}

.right-side {
  padding: 0;
  background: #f1f5f7 !important;
}

.main .left-side {
  /* width: 13%; */
}

.main .form {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
}

.container {
  background: #000000;
  position: relative;
  margin: 0 5px;
}

.zoom {
  position: absolute;
  right: 30px;
  bottom: 26px;
  z-index: 99;
}

/* 
.main .btn-submit{
    position: absolute;
    bottom: 25px;
    left:50%;
    transform: translate(-50%,0);
} */
.step-list .item {
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 4px;
  color: #364284;
  margin-top: 14px;
  padding: 12px 22px;
}

.step-list .active {
  background: #364284;
  color: #ffffff;
}

.btn-submit,
.btn-comfirm,
.btn-revocation {
  width: 198px;
  height: 38px;
  line-height: 38px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 auto;
  color: #ffffff;
  background: #364284;
  border: 1px solid #364284;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.btn-revocation {
  background: #f1f5f7;
  border: 1px solid #364284;
  color: #364284;
  margin-top: 10px;
  margin-bottom: 50px;
}

.content-title {
  font-size: 14px;
  border-bottom: none;
}

.el-form-item {
  margin-bottom: 2px;
}

.popover {
  padding: 10px;
  background-color: #fff;
  position: fixed;
  border-radius: 5px;
}

.popover::before {
  content: "";
  position: absolute;
  border: 8px solid;
  border-color: #ffffff transparent transparent transparent;
  right: 32px;
  top: 35px;
}

.left-side-form,
.right-side-form {
  display: flex;
}
.left-side-form-item,
.right-side-form-item {
  flex: 1;
}
</style>
